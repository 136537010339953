// @flow
import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Header from "../components/header";
import ImageCard from "../components/ImageCard";
import Page from "../components/Page";
import Section from "../components/Section";
import { MEDIUM_BREAKPOINT, query } from "../style";
import type { ImageSharp, ImageType } from "../types.js";

type Props = {
  data: {
    mainImage: ImageType,
    secondImage: ImageType,
    thirdImage: ImageType,
    teamImage: ImageType,
    treatmentsImg: ImageType,
    preparing: ImageType,
    dayOf: ImageType,
    expect: ImageType,
    recovery: ImageType
  }
};

export default class Index extends React.Component<Props> {
  render() {
    const {
      mainImage,
      secondImage,
      thirdImage,
      teamImage,
      treatmentsImg,
      preparing,
      dayOf,
      expect,
      recovery
    } = this.props.data;
    return (
      <Page title="Procedure and Recovery">
        <Header
          mainImage={mainImage.childImageSharp}
          secondImage={secondImage.childImageSharp}
          thirdImage={thirdImage.childImageSharp}
          title="Procedure and Recovery"
          subtitle="Dealing with a spine problem can be anxiety inducing. Our commitment is to help you through your care and recovery, treating you as a person, not just a patient."
          cta="Make an appointment"
        />

        <WhatWeTreat color="#fff">
          <DifferentSectionHeader>
            <SectionTitle>What to expect</SectionTitle>
          </DifferentSectionHeader>
          <TreatmentWrap>
            <TreatmentsImage image={expect.childImageSharp} />
            <TreatWrap style={{ flex: 1, margin: 20 }}>
              <TreatmentBody>
                We treat every patient individually, and as such, no two
                procedures are exactly the same. In the appointments leading up
                to your procedure, your care team will discuss the various
                aspects with you, taking care to listen to your concerns and
                make sure you understand what to expect. We will also give you a
                detailed information packet with everything you need to know. If
                you have any questions or concerns, we encourage you to bring
                them up with your providers, but don’t ever hesitate to call us
                afterwards if you have any lingering questions. We want you to
                be informed and confident.
              </TreatmentBody>
            </TreatWrap>
          </TreatmentWrap>
        </WhatWeTreat>

        <WhatWeTreat color="#D0E5FF">
          <DifferentSectionHeader>
            <SectionTitle>Preparing for your procedure</SectionTitle>
          </DifferentSectionHeader>
          <TreatmentWrap>
            <TreatWrap style={{ flex: 1, margin: 20 }}>
              <TreatmentBody>
                Preparation is key to a comfortable procedure experience. Our
                physicians understand this, and will provide you with all the
                information you need to feel confident and relaxed. Your care
                team will discuss the following questions with you prior to your
                procedure, but be sure to bring up any questions or worries you
                might have. We’re always happy to talk and explain anything you
                have questions about!
              </TreatmentBody>
              <Questions>
                <Q>
                  What should I do prior to the procedure? Is there anything I
                  can’t do?
                </Q>
                <Q>
                  Do I need to change my medications or diet at all prior to the
                  procedure?
                </Q>
                <Q>
                  Will I need someone to help me after my procedure? What plans
                  should I make?
                </Q>
                <Q>How do we deal with insurance?</Q>
              </Questions>
            </TreatWrap>
            <TreatmentsImage image={preparing.childImageSharp} />
          </TreatmentWrap>
        </WhatWeTreat>

        <WhatWeTreat color="#8B8DC0">
          <DifferentSectionHeader>
            <SectionTitle style={{ color: "white" }}>
              The day of your procedure
            </SectionTitle>
          </DifferentSectionHeader>
          <TreatmentWrap>
            <TreatmentsImage image={dayOf.childImageSharp} />
            <TreatWrap style={{ flex: 1, margin: 20 }}>
              <TreatmentBody style={{ color: "white" }}>
                The day of your procedure might seem daunting, but one of our
                top concerns is ensuring you are at ease and well informed. As
                we said, each procedure is unique, but your care team will be
                sure to give you a detailed information packet and discuss these
                questions with you in advance:
              </TreatmentBody>
              <Questions style={{ color: "white" }}>
                <Q>Should somebody come with me?</Q>
                <Q>What should I bring, if anything?</Q>
                <Q>How long will the procedure last?</Q>
                <Q>Are there any risk factors I should be aware of?</Q>
              </Questions>
            </TreatWrap>
          </TreatmentWrap>
        </WhatWeTreat>

        <WhatWeTreat color="#fff" style={{ marginBottom: 120 }}>
          <DifferentSectionHeader>
            <SectionTitle>Road to recovery</SectionTitle>
          </DifferentSectionHeader>
          <TreatmentWrap>
            <TreatWrap style={{ flex: 1, margin: 20 }}>
              <TreatmentBody>
                Once your procedure is over, the focus turns to recovery, which
                is equally as important to the success of your treatment as
                everything leading up to it. We will make sure you know not only
                the steps to care for yourself immediately after your procedure,
                but that you also have a complete plan to regain strength. Your
                medical team will discuss at least the following questions with
                you:
              </TreatmentBody>
              <Questions>
                <Q>Is bracing an option for me?</Q>
                <Q>Physical Therapy?</Q>
                <Q>Follow up visits</Q>
              </Questions>
            </TreatWrap>
            <TreatmentsImage image={recovery.childImageSharp} />
          </TreatmentWrap>
        </WhatWeTreat>

        <GetStarted />
        <Footer />
      </Page>
    );
  }
}
const Questions = styled.ul``;
const Q = styled.li``;

const TREAT_BREAKPOINT = 925;

const TreatWrap = styled.div`
  && {
    flex: 1;
    margin: auto;
    max-width: 490px;
  }
`;

const TreatmentWrap = styled.div`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
`;

const TreatmentBody = styled.p`
  && {
    flex: 1;
  }
`;

const TreatmentsImage = styled(ImageCard)`
  && {
    display: none;
    flex: 1;
    ${query(TREAT_BREAKPOINT)`
    display: block;
  `};
  }
`;

const WhatWeTreat = styled(Section)`
  && {
    ${query(MEDIUM_BREAKPOINT)`
      padding-top: 250px;
    `}
  }
`;
const SectionTitle = styled.h1`
  && {
    margin-top: 30px;
  }
`;

const SectionHeader = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    ${query(MEDIUM_BREAKPOINT)`
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 30px;
  `};
  }
`;

const DifferentImage = styled(Img)`
  && {
    width: 100%;
    height: auto;
    max-width: 700px;
    margin: auto;
    display: block;
  }
`;

const DifferentSectionHeader = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    ${query(MEDIUM_BREAKPOINT)`
      margin-top: 40px;
  `};
  }
`;

export const pageQuery = graphql`
  query procedureAndRecovery {
    mainImage: file(relativePath: { eq: "square/xray-people.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 600) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    secondImage: file(relativePath: { eq: "square/reception.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    thirdImage: file(relativePath: { eq: "square/pt.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    preparing: file(relativePath: { eq: "procedure/sam.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 600) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70, width: 1040) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    dayOf: file(relativePath: { eq: "procedure/surgery.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 700) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    expect: file(relativePath: { eq: "procedure/nurse.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 700) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    recovery: file(relativePath: { eq: "procedure/pt.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 700) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`;
